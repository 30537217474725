import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from 'react';

interface IRelationshipNotesModalContext {
  isDirtyNote: boolean;
  setIsDirtyNote: React.Dispatch<React.SetStateAction<boolean>> | (() => never);
  isDiscardNoteChangesModalOpen: boolean;
  setIsDiscardNoteChangesModalOpen:
    | React.Dispatch<React.SetStateAction<boolean>>
    | (() => never);
  onConfirmDiscardNoteChanges: (() => void) | undefined;
  setOnConfirmDiscardNoteChanges:
    | React.Dispatch<React.SetStateAction<(() => void) | undefined>>
    | (() => never);
}

export const RelationshipNotesModalContext =
  createContext<IRelationshipNotesModalContext>({
    isDirtyNote: false,
    setIsDirtyNote: () => {
      throw new Error('setIsDirtyNote is not implemented');
    },
    isDiscardNoteChangesModalOpen: false,
    setIsDiscardNoteChangesModalOpen: () => {
      throw new Error('setIsDiscardNoteChangesModalOpen is not implemented');
    },
    onConfirmDiscardNoteChanges: () => {
      throw new Error('onConfirmDiscardNoteChanges is not implemented');
    },
    setOnConfirmDiscardNoteChanges: () => {
      throw new Error('setOnConfirmDiscardNoteChanges is not implemented');
    },
  });

export const RelationshipNotesModalProvider = ({
  children,
}: PropsWithChildren) => {
  const [isDirtyNote, setIsDirtyNote] = useState(false);
  const [isDiscardNoteChangesModalOpen, setIsDiscardNoteChangesModalOpen] =
    useState(false);
  const [onConfirmDiscardNoteChanges, setOnConfirmDiscardNoteChanges] =
    useState<() => void>();

  const value = {
    isDirtyNote,
    setIsDirtyNote,
    isDiscardNoteChangesModalOpen,
    setIsDiscardNoteChangesModalOpen,
    onConfirmDiscardNoteChanges,
    setOnConfirmDiscardNoteChanges,
  };

  useEffect(() => {
    if (onConfirmDiscardNoteChanges === undefined) return;

    if (isDirtyNote) {
      setIsDiscardNoteChangesModalOpen(true);
    } else {
      onConfirmDiscardNoteChanges?.();
    }
  }, [onConfirmDiscardNoteChanges]);

  return (
    <RelationshipNotesModalContext.Provider value={value}>
      {children}
    </RelationshipNotesModalContext.Provider>
  );
};
